import { Box, Typography, Avatar, Button } from '@mui/material';

const ProfilePage = () => {
  return (
    <Box textAlign="center" mt={5}>
      <Avatar
        alt="User Avatar"
        src="/path-to-avatar.png"
        sx={{ width: 100, height: 100, mx: 'auto' }}
      />
      <Typography variant="h4" mt={2}>Username</Typography>
      <Typography variant="body1" color="textSecondary">Level: 10</Typography>
      <Button variant="outlined" color="secondary" sx={{ mt: 3 }}>
        View Friends
      </Button>
    </Box>
  );
};

export default ProfilePage;
