import { AppBar, Toolbar, Typography, Button } from '@mui/material';

interface NavbarProps {
  isAuthenticated: boolean;
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isAuthenticated, onLogout }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Gnome Agotchi
        </Typography>
        {isAuthenticated ? (
          <>
            <Button color="inherit" href="/">Home</Button>
            <Button color="inherit" href="/profile">Profile</Button>
            <Button color="inherit" onClick={onLogout}>Logout</Button>
          </>
        ) : (
          <>
            <Button color="inherit" href="/login">Login</Button>
            <Button color="inherit" href="/signup">Sign Up</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
