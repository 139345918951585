import { Box, Container, Typography, Button } from '@mui/material';

const HomePage = () => {
  return (
    <Container maxWidth="md">
      <Box textAlign="center" mt={5}>
        <Typography variant="h2">Welcome to Gnome Agotchi!</Typography>
        <Typography variant="body1" mt={2}>
          Join our virtual world, make friends, and play games!
        </Typography>
        <Box mt={3}>
          <Button variant="contained" color="primary" href="/profile">
            Enter the World
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default HomePage;
