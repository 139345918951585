import React, { useState } from 'react';
import { Button, Box, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {
  onLogin: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (event: React.FormEvent) => {
        event.preventDefault();

        onLogin();
        navigate('/'); // Redirect to home after login
    }

    return (
        <Box textAlign="center" mt={5} component="form" onSubmit={handleLogin}>
        <Typography variant="h4">Login to Gnome Agotchi</Typography>
        <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
        />
        <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Log In
        </Button>
        </Box>
    );
};

export default LoginPage;
