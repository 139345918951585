import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: 4,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body2" color="textSecondary" align="center">
          © {new Date().getFullYear()} Gnome Agotchi. All rights reserved.
        </Typography>
        <Box textAlign="center" mt={1}>
          <Link href="/" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Home
          </Link>
          <Link href="/profile" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Profile
          </Link>
          <Link href="/about" color="inherit" underline="hover" sx={{ mx: 1 }}>
            About
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
